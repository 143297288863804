<template>
  <div class>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="隔离告知书" name="1"></el-tab-pane>
      <el-tab-pane label="解除隔离告知书" name="2"></el-tab-pane>
    </el-tabs>

    <div class="condition-filtrate-box">
      <div class="left-item">
        <el-input
          class="select-width id-code-width"
          size="medium"
          v-model="queryForm.searchValue"
          placeholder="居民姓名/手机号"
        />

        <el-select
          class="select-width"
          size="medium"
          v-model="queryForm.crowdTypeId"
          placeholder="人群分类"
        >
          <el-option
            v-for="item in typepopSelect"
            :key="item.id"
            :label="item.ruleName"
            :value="item.id"
          />
        </el-select>

        <el-select class="select-width" size="medium" v-model="queryForm.status" placeholder="存档状态">
          <el-option
            v-for="(item,v) in archiveStatus"
            :key="v"
            :label="item.label"
            :value="item.value"
          />
        </el-select>

        <el-date-picker
          v-model="pickerTime"
          size="medium"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
      </div>

      <div class="right-item">
        <el-button type="primary" size="small" @click="queryCheckRecordInfo">查询</el-button>
        <el-button type="primary" size="small" @click="resetQueryInfo">重置</el-button>
      </div>
    </div>

    <TableModel
      :is-show-select="true"
      :is-show-tabs="false"
      :loading="loading"
      :table-data="notifyManageList"
      table-size="medium"
    >
      <el-table-column label="操作" width="130">
        <template slot-scope="{ row }">
          <el-link
            type="primary"
            v-if="(row.regChannel == 0 || row.regChannel == 3 )&& row.archiveStatus == 1"
            @click="editNotify(row)"
          >修改</el-link>
          <el-link
            type="primary"
            v-if="(row.regChannel == 0  || row.regChannel == 3 )&& row.archiveStatus != 1"
            @click="uploadNotify(row.id)"
          >上传</el-link>

          <template
            v-if="row.regChannel == 1  || row.regChannel == 3 || (row.regChannel == 0 && row.archiveStatus == 1)"
          >
            <el-link type="primary" @click="downloadNotifyInfo(row)">下载</el-link>
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="居民信息" />
      <el-table-column prop="sex" label="性别" width="90" />
      <el-table-column prop="age" label="年龄" width="90" />
      <el-table-column prop="linkPhone" label="手机号" />
      <el-table-column prop="roomNo" label="房间号" width="90" />
      <el-table-column prop="isolationPointName" label="隔离点" />
      <el-table-column prop="crowdTypeName" label="人群分类" width="220" />
      <el-table-column prop="startTime" label="开始隔离时间" width="220" v-if="activeName == 1" />
      <el-table-column prop="transferTime" label="解除隔离时间" width="220" v-else />
      <el-table-column label="登记渠道">
        <template slot-scope="scope">
          <span v-if="scope.row.regChannel == 0">现场</span>
          <span v-if="scope.row.regChannel == 1">小程序</span>
          <span v-if="scope.row.regChannel == 2">酒店转进</span>
          <span v-if="scope.row.regChannel == 3">流调登记</span>
          <span v-if="scope.row.regChannel == 4">居家隔离转进</span>
          <span v-if="scope.row.regChannel == 5">居家监测转进</span>
          <span v-if="scope.row.regChannel == 6">街镇转进</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="存档状态">
        <template slot-scope="{ row }">
          <template v-if="row.regChannel == 0">
            <el-tag size="small" type="success" v-if="row.archiveStatus == 1">已存档</el-tag>
            <el-tag size="small" v-else>未存档</el-tag>
          </template>
          <template v-if="row.regChannel == 1">
            <el-tag size="small" type="success">已存档</el-tag>
          </template>
          <template v-if="row.regChannel == 3">
            <el-tag size="small" type="success" v-if="row.archiveStatus == 1">已存档</el-tag>
            <el-tag size="small" v-else>未存档</el-tag>
          </template>
        </template>
      </el-table-column>
    </TableModel>

    <div class="content-page">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNumber"
        :page-size="pageSize"
        :total="total"
        :page-sizes="[10, 20, 40, 50, 100]"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>

    <Dialog
      ref="diglogRef"
      :dialog-title="dialogTitle"
      :is-show-dialog="formDialog"
      label-width="100px"
      @closeDialog="closeDialog"
      @submitForm="submitFormInfo"
    >
      <el-form-item label="上传告知书：">
        <UploadFile ref="UploadFileRef" @uploadSuccess="uploadSuccess" @clearData="clearData" />
      </el-form-item>
    </Dialog>
  </div>
</template>

<script>
import TableModel from "../../components/TableModel";
import Dialog from "../../components/Dialog";
import UploadFile from "../../components/UploadFile";
import constants from "../../common/constants";
import {
  messageBox,
  downloadExportFile,
  downloadIamge
} from "../../utils/utils";
import {
  downloadEntryBook,
  downloadRelieveSignBook,
  noticeList,
  saveNotice
} from "../../api/QuarantineRegistration/quarantineRegistrationpop";
import { mapState } from "vuex";

export default {
  name: "NotifyManage",
  components: { TableModel, Dialog, UploadFile },
  data() {
    return {
      userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
      roles: JSON.parse(localStorage.getItem("roles")),
      loading: false,
      notifyManageList: [],
      pageNumber: 1,
      pageSize: 20,
      total: 0,
      queryForm: {
        searchValue: "",
        crowdTypeId: "",
        status: ""
      },
      formDialog: false,
      activeName: "1",
      pickerTime: [],
      archiveStatus: constants.ARCHIVE_STATUS, // 存档状态
      dialogTitle: "",
    };
  },

  computed: {
    ...mapState({
      typepopSelect: state => state.user.typepopSelect // 人群分类
    })
  },

  created() {
    this.getNotifyManage();
  },

  methods: {
    handleClick(tab) {
      this.activeName = tab.name;
      this.pageNumber = 1;
      this.resetQueryInfo("tabs");
      this.getNotifyManage();
    },

    // 设置请求参数
    setParamsInfo() {
      let params = {
        hospitalId: this.userdoctor.hospitalId,
        orgCode: this.userdoctor.orgCode,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        type: this.activeName == 1 ? 1 : 2,
        status: this.activeName == 1 ? 1 : 0
      };

      let { searchValue, crowdTypeId, status } = this.queryForm;
      if (searchValue) params.searchValue = searchValue;
      if (crowdTypeId) params.crowdTypeId = crowdTypeId;
      if (status) params.archiveStatus = status;

      if (this.pickerTime && this.pickerTime.length == 2) {
        params.startTimeMin = this.pickerTime[0] + " 00:00:00";
        params.startTimeMax = this.pickerTime[1] + " 23:59:59";
      }

      return params;
    },

    async getNotifyManage() {
      this.loading = true;
      let params = this.setParamsInfo();
      console.log(params);
      let result = await noticeList({ params });
      this.renderListData(result);
    },

    // 渲染数据
    renderListData(result) {
      let { code, data, msg } = result.data;
      if (code == 200) {
        let { totalRow, list } = data;
        list.forEach(item => {
          constants.SEX_LIST.forEach(key => {
            if (item.sex == key.value) {
              item.userInfo = `${item.name} ${key.label} ${item.age}岁 ${item.linkPhone}`;
              item.sex = key.label;
              item.age = item.age + "岁";
            }
          });
        });
        this.notifyManageList = list;
        this.total = totalRow;
      } else {
        this.$message.error(msg);
      }
      this.loading = false;
    },

    // 条件查询
    queryCheckRecordInfo() {
      this.pageNumber = 1;
      this.getNotifyManage();
    },

    // 重置条件
    resetQueryInfo(val) {
      this.pickerTime = [];
      this.queryForm = {
        searchValue: "",
        crowdTypeId: "",
        status: ""
      };
      if (val !== "tabs") this.getNotifyManage();
    },

    uploadSuccess(imgUrl) {
      this.imgUrl = imgUrl;
    },

    // 下载
    downloadNotifyInfo(item) {
      let params = {
        regId: item.id
      };
      // console.log(11111,item);
      if(!item.archiveUrl){
        this.$message.warning("没有告知书图片数据");
        return;
      }
      messageBox(
        async () => {
          // 登记渠道为小程序
          if (item.regChannel == 1) {
            let result;
            if (this.activeName == 1) {
              result = await downloadEntryBook({ params });
            } else {
              result = await downloadRelieveSignBook({ params });
            }
            let url = window.URL.createObjectURL(result.data);
            window.open(url);
          } else if (item.regChannel == 0 ||item.regChannel == 3) {
            // 现场或流调
            let isPng = item.archiveUrl.indexOf(".png");
            let isJpg = item.archiveUrl.indexOf(".jpg");
            let isJpeg = item.archiveUrl.indexOf(".jpeg");
            if (isPng !== -1 || isJpg !== -1 || isJpeg !== -1) {
              downloadIamge(item.archiveUrl, "告知书");
            } else {
              downloadExportFile(item.archiveUrl, "告知书");
            }
          }
        },
        "warning",
        "是否确认下载当前告知书信息吗？"
      );
    },

    // 修改
    editNotify(row) {
      this.dialogTitle = "修改文件";
      this.formDialog = true;
      this.$nextTick(() => {
        this.$refs.UploadFileRef.srcList = [row.archiveUrl];
      });
      this.$refs.diglogRef.openDialog("edit", row.id);
    },

    // 上传
    uploadNotify(id) {
      this.dialogTitle = "上传文件";
      this.formDialog = true;
      this.$nextTick(() => {
        this.$refs.UploadFileRef.resetData();
      });
      this.$refs.diglogRef.openDialog("upload", id);
    },

    closeDialog() {
      this.formDialog = false;
    },

    // 清除已上传的图片信息
    clearData() {
      this.imgUrl = null;
    },

    async submitFormInfo(data) {
      if (!this.imgUrl) return this.$message.warning("请上传告知书图片");

      let params = {
        regId: data.id,
        archiveUrl: this.imgUrl,
        type: this.activeName == 1 ? 1 : 2
      };
      let result = await saveNotice(params);
      let { code, msg } = result.data;
      if (code == 200) {
        this.$message.success(msg);
        this.$refs.diglogRef.closeDialog();
        this.getNotifyManage();
      } else {
        this.$message.error(msg);
      }
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.getNotifyManage();
    },

    handleCurrentChange(val) {
      this.pageNumber = val;
      this.getNotifyManage();
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../static/css/condition-filtrate.scss";
</style>

<style scoped>
.el-link {
  margin-right: 10px;
}

.el-table--medium >>> td {
  padding: 6px 0;
}
</style>
